import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component'
import { BaseUrlInterceptor } from './interceptors/api-interceptor';;
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SimplePageComponent } from './simple-page/simple-page.component';
import { SmartPageComponent } from './smart-page/smart-page.component';
import { StablePageComponent } from './stable-page/stable-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { DemoPageComponent } from './demo-page/demo-page.component';
import { DialogDemoComponent } from './demo-page/dialog-demo/dialog-demo.component';
import { MatDialogModule } from '@angular/material/dialog';

import { environment } from '../environments/environment';


@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
  
    // ...MODULES,
  ],
  declarations: [
    AppComponent,
    SidebarMenuComponent,
    HomePageComponent,
    SimplePageComponent,
    SmartPageComponent,
    StablePageComponent,
    DemoPageComponent,
    ContactPageComponent,
    DialogDemoComponent,

    // ...COMPONENTS,
  ],
  entryComponents: [
    DialogDemoComponent,

    // ...ENTRY_COMPONENTS,
  ],
  providers: [
    { provide: 'BASE_AUTH_URL', useValue: environment.authUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: HammerConfig,
    // }
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
