import { SideMenu, HomePage, SimplePage, SmartPage, StablePage, DemoPage, ContactPage } from './content';

export const SIDEMENU: SideMenu = {
  th:{
    detail1: 'ภาพรวม',
    detail2: 'ใช้งานง่าย',
    detail3: 'ฉลาด',
    detail4: 'เชื่อถือได้',
    // detail5: 'ขอรับสิทธิ์',
    detail5: 'ติดต่อเรา',
  },
  en:{
    detail1: 'Home Page',
    detail2: 'Simple',
    detail3: 'Smart',
    detail4: 'Stable',
    // detail5: 'Demo',
    detail5: 'Contact',
  }
};

export const HOMEPAGE: HomePage = {
  th: {
    detail1: 'สัมผัสประสบการณ์ที่ดีที่สุด',
    detail2: 'ของ',
    detail3: 'GPS',
    detail4: 'แพลตฟอร์มสำหรับธุรกิจคุณ',
    detail5: 'ติดต่อเรา : 062-801-4748',
  },
  en: {
    detail1: 'Best Experience',
    detail2: '  ',
    detail3: 'GPS',
    detail4: 'platform for the Bussiness',
    detail5: 'Tel : 062-801-4748',
  },
}

export const SIMPLEPAGE: SimplePage = {
  th: {
    detail1: 'ใช้ง่าย อย่างที่ไม่เคยมีมาก่อน',
    detail2: 'สรุปข้อมูลสำคัญทั้งหมดในหน้าเดียว​',
    detail3: 'ทำให้การจัดการเป็นเรื่องง่ายในไม่กี่คลิก​',
    detail4: 'แสดงผลรายงานผ่านโปรแกรมที่คุณใช้อยู่ทุกวัน​',
  },
  en: {
    detail1: 'Easy to use, like never have before',
    detail2: 'All information you need in the single page.',
    detail3: 'All management as easy in a few clicks.',
    detail4: 'Report via your daily messenger channel.',
  },
}

export const SMARTPAGE: SmartPage = {
  th: {
    detail1: 'รู้เรื่อง รู้ไว ใช้ได้ทันที',
    detail2: 'ประเมินค่าใช้จ่ายของรถในแต่ละวัน​',
    detail3: 'รายงานเหตุการณ์ผิดปกติให้คุณแบบเรียลไทม์',
    detail4: 'คิด วิเคราะห์ และให้ข้อมูลที่สำคัญต่อธุรกิจ',
  },
  en: {
    detail1: 'รู้เรื่อง รู้ไว ใช้ได้ทันที',
    detail2: 'Estimate cost of the car per day.',
    detail3: 'Report unusual event in real-time.',
    detail4: 'Think, analyze and represent important business information',
  },
}

export const STABLEPAGE: StablePage = {
  th: {
    detail1: 'ดีกว่า ปลอดภัย ไร้กังวล',
    detail2: 'M-Link ระบบปฎิบัติการที่ถูกออกแบบมาเพื่อประสิทธิภาพ',
    detail3: 'และความเสถียรโดยเฉพาะ',
    detail4: 'ระบบสำรองทำงานทันทีที่ระบบหลักมีปัญหา​',
    detail5: 'ขยายฐานข้อมูลไม่จำกัด รองรับข้อมูลในอนาคต',
    detail6: 'ระบบความปลอดภัยของข้อมูลแบบที่ธนาคารชั้นนำเลือกใช้',
  },
  en: {
    detail1: 'ดีกว่า ปลอดภัย ไร้กังวล',
    detail2: 'M-Link Operating system that is specifically',
    detail3: 'designed for performance and stability',
    detail4: 'high availability system design',
    detail5: 'Unlimited data expansion.',
    detail6: 'Data security system as chosen by leading bank.',
  },
}

export const DEMOPAGE: DemoPage = {
  th: {

    detail1: 'ชื่อ :',
    detail2: 'เบอร์โทร :',
    detail3: 'อีเมล์ :',
    detail4: 'ข้อความ : ',
    detail5:  'ขอรับสิทธิ์ใช้งาน',

  },
  en: {
    detail1: 'Name :',
    detail2: 'Phone :',
    detail3: 'E-mail :',
    detail4: 'Password :',
    detail5: 'Request for Free Trail',
  },
}

export const CONTACTPAGE: ContactPage = {
  th: {
    detail1: 'บริษัท อิมเม็กซ์ เทคโนโลยี จำกัด',
    detail2: 'เลขที่ 1 ซอยลาดพร้าว 17 ถนนลาดพร้าว',
    detail3: 'แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900​​',
    detail4: '062-8013631, 062-8014748',
    detail5: 'info@imextechs.com',
    detail6: 'LOCA Software',
    detail7: '@imextechs',

    
  },
  en: {
    detail1: 'IMEX TECHNOLOGY CO.,LTD.​​',
    detail2: 'Address: 1 Soi Ladprao 17, Ladprao Road,',
    detail3: 'Chomphon, Chatuchak, Bankok, Thailand 1090​​0​​',
    detail4: '+6662-8013631, +6662-8014748',
    detail5: 'info@imextechs.com',
    detail6: 'LOCA Software',
    detail7: '@imextechs',
  },
}