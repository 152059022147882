import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


import { SideMenu, HomePage, SimplePage, SmartPage, StablePage, DemoPage, ContactPage } from './content';
import { SIDEMENU, HOMEPAGE, SIMPLEPAGE, SMARTPAGE, STABLEPAGE, DEMOPAGE, CONTACTPAGE } from './content-default';

@Injectable({ providedIn: 'root' })
export class ContentService {
  page: string = 'homePage';
  pageIndex: number = 0;
  lang: string = 'th';
  name: string;
  phone: string;
  email: string;
  message: string;

  sideMenu: SideMenu = SIDEMENU;
  homePage: HomePage = HOMEPAGE;
  simplePage: SimplePage = SIMPLEPAGE;
  smartPage: SmartPage = SMARTPAGE;
  stablePage: StablePage = STABLEPAGE;
  demoPage: DemoPage = DEMOPAGE;
  contactPage: ContactPage = CONTACTPAGE;

  pages: string[] = ['homePage', 'simplePage', 'smartPage', 'stablePage','contactPage'];

  constructor(private http: HttpClient) {}


  setPage(page: string) {
    this.page = page;
    let pageIndex = this.pages.findIndex(page => {
      return page === this.page;
    })
    if (pageIndex > -1) {
      this.pageIndex = pageIndex;
    }
  }

  setPageIndex(number: number) {
    this.pageIndex = this.pageIndex + number;
    if (this.pageIndex < 0) {
      this.pageIndex = 0;
    }
    if (this.pageIndex > this.pages.length - 1) {
      this.pageIndex = this.pages.length - 1;
    }
    this.page = this.pages[this.pageIndex];
  }

  setLanguage(lang: string) {
    this.lang = lang;
  }

  dispatchDemoRegister(param: any): Observable<any> {
    return this.http.post<any>('api/imex/demoregister', param);
  }
}
