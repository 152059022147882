import { Component } from '@angular/core';
import { ContentService } from '../content.service';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

@Component({
  selector: 'sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent {
  
  // showContent: boolean = true;

  constructor(public contentService:ContentService){}
  
  ngOnInit(){
    AOS.init();
  }
}