import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from '../../responsive.service';

@Component({
  selector: 'dialog-demo',
  templateUrl: './dialog-demo.component.html',
  styleUrls: ['./dialog-demo.component.scss'],
})
export class DialogDemoComponent /*implements OnInit*/ {

  public isMobile: Boolean;

  constructor(public responsiveService: ResponsiveService, ) {}

  // cancel() {
  //   this.ref.close();
  // }

  // submit(email) {
  //   this.ref.close(email);
  // }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }
  
}