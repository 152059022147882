import { Component } from '@angular/core';
import { ContentService } from './content.service'; 
import { ResponsiveService } from './responsive.service';
// import { NbDialogService } from '@nebular/theme'; 

import 'hammerjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  
})
export class AppComponent {
  public isMobile: Boolean;
  title = 'imextechs-website';
  boundary: number = 4;
  mousewheelPoint = 0;
  touchStartPosition = 0;

  constructor(public contentService:ContentService, public responsiveService: ResponsiveService,){}

  ngOnInit() {
    window.addEventListener('mousewheel', this.mousewheelHandler, true);
    window.addEventListener('touchstart', this.touchStartHandler, true);
    window.addEventListener('touchend', this.touchEndHandler, true);

    this.onResize();
    this.responsiveService.checkWidth();
    
  }

  ngOnDestroy() {
    window.removeEventListener('mousewheel', this.mousewheelHandler, true);
    window.removeEventListener('touchstart', this.touchStartHandler, true);
    window.removeEventListener('touchend', this.touchEndHandler, true);
  }

  mousewheelHandler = ($event): void => {
    let delta = $event.wheelDelta;
    if(delta > 0){
      if (this.mousewheelPoint > 0) {
        this.mousewheelPoint--;
      }
    }
    else{
      if (this.mousewheelPoint < 2 * this.boundary) {
        this.mousewheelPoint++;
      }
    }
    if (this.mousewheelPoint === 0) {
      this.contentService.setPageIndex(-1);
      this.mousewheelPoint = this.boundary;
    }
    if (this.mousewheelPoint === 2 * this.boundary) {
      this.contentService.setPageIndex(1);
      this.mousewheelPoint = this.boundary;
    }
  };

  touchStartHandler = ($event): void => {
    this.touchStartPosition = $event.touches[0].clientY;
  }

  touchEndHandler = ($event): void => {
    let touchEndPosition = $event.changedTouches[0].clientY;
    if(this.touchStartPosition > touchEndPosition + 100){
      this.contentService.setPageIndex(1);
    }else if(this.touchStartPosition < touchEndPosition - 100){
      this.contentService.setPageIndex(-1);
    }
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      // console.log(isMobile);
      this.isMobile = isMobile;
    });
  }
 





}
