import { Component, Input } from '@angular/core';
import { ContentService } from '../content.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'stable-page',
  templateUrl: './stable-page.component.html',
  styleUrls: ['./stable-page.component.scss'],
})

export class StablePageComponent {
  public isMobile: Boolean;

  constructor(public contentService:ContentService, public responsiveService: ResponsiveService){
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
  }
}