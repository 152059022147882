import {Injectable, Inject} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BaseUrlInterceptor implements HttpInterceptor {

  constructor (@Inject('BASE_AUTH_URL') public authUrl: string) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authUrl = ['api/imex/demoregister'];
    let url = `${req.url}`;
    if (authUrl.indexOf(req.url) > -1) {
      url = `${this.authUrl}/${req.url}`;
    }
    const options = {
      url: url,
    };
    const apiReq = req.clone(options);
    return next.handle(apiReq);
  }

}
