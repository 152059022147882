import { Component, Input } from '@angular/core';
import { ContentService } from '../content.service';
import { ResponsiveService } from '../responsive.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDemoComponent } from './dialog-demo/dialog-demo.component';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'demo-page',
  templateUrl: './demo-page.component.html',
  styleUrls: ['./demo-page.component.scss'],

})

export class DemoPageComponent {

  public isMobile: Boolean;
  demoForm: FormGroup;
  submitted: boolean = false;

  constructor(public contentService:ContentService,
              public responsiveService: ResponsiveService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog){
              
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.demoForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('.+@.+\..+')]],
      message: [],
    });

    this.onResize();
    this.responsiveService.checkWidth();
  }

  get demo() { return this.demoForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.demoForm.invalid) {
      return;
    } else {
      let param = {
        name: this.demoForm.value.name,
        phone: this.demoForm.value.phone,
        email: this.demoForm.value.email,
        message: this.demoForm.value.message,
      };
      this.contentService.dispatchDemoRegister(param)
      .subscribe(response => {
        // this.openDialog();
        // console.log(response);
      });
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogDemoComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}