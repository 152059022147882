import { Component, Input } from '@angular/core';
import { ContentService } from '../content.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})


export class ContactPageComponent {
  public isMobile: Boolean;

  constructor(public contentService:ContentService,
    public responsiveService: ResponsiveService){
}

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
    this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
  }

}