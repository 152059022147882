import { Component, Input } from '@angular/core';
import { ContentService } from '../content.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'smart-page',
  templateUrl: './smart-page.component.html',
  styleUrls: ['./smart-page.component.scss'],
})

export class SmartPageComponent {
  public isMobile: Boolean;

  constructor(public contentService:ContentService,              
    public responsiveService: ResponsiveService){
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
  }
  
}