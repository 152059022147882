import { Component, Input } from '@angular/core';
import { ContentService } from '../content.service';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})

export class HomePageComponent {
  public isMobile: Boolean;

  constructor(public contentService:ContentService,
    public responsiveService: ResponsiveService){
  }
  
    onResize() {
      this.responsiveService.getMobileStatus().subscribe(isMobile => {
        this.isMobile = isMobile;
      });
    }
  
    ngOnInit() {
      this.onResize();
      this.responsiveService.checkWidth();
    }
}